import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import Loader from "pages/Loader"
import user1 from "../../assets/images/default-user.png"
import swal from "sweetalert"
import { getDecryptedData } from "pages/Config"
import axios from "axios"
import { ConfigUrl } from "ConfigUrl"

const Profile = () => {
  const navigate = useNavigate()

  const onDeleteAccount = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete your account?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        handleDeleteAccount()
      }
    })
  }

  async function handleDeleteAccount() {
    try {
      const formData = new FormData()
      formData.append("client_id", localStorage.getItem("client_id"))
      formData.append("added_by", localStorage.getItem("added_by"))
      formData.append("status", 0)

      const response = await axios.post(
        `${ConfigUrl.apiUrl}client_status`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (response.data.success == true) {
        swal("", "Your Account has been deleted successfully", "success").then(
          () => {
            localStorage.removeItem("client_id")
            localStorage.removeItem("gender")
            localStorage.removeItem("fullname")
            localStorage.removeItem("addedby")
            navigate(`${ConfigUrl.appUrl}login`)
          }
        )
      }
    } catch (error) {
      console.log(error)
      swal("", "Something Went Wrong!", "success")
    }
  }

  return (
    <>
      <div className="page-content">
        {/* {
                    loading && <Loader />
                } */}
        <Container fluid>
          {/* <div className="px-3">
                        <Breadcrumbs
                            title={"Dashboard"}
                            breadcrumbItem={"Invoices"}
                        />
                    </div> */}
          <Row className="mx-0">
            <Card>
              <CardBody className="px-2">
                <div>
                  <div>
                    <h5 className="text-purple fw-bold fs-4 d-flex justify-content-between align-items-center">
                      <span className="fs-6" onClick={() => navigate(-1)}>
                        {" "}
                        <i className="fas fa-arrow-left me-1"></i> Go Back
                      </span>
                    </h5>
                  </div>
                  <div className="text-center ">
                    <img
                      className="rounded-circle header-profile-user border"
                      style={{ width: "100px", height: "100px" }}
                      src={user1}
                      alt="Header Avatar"
                    />
                    <h3 className=" mt-2 mb-4 text-purple">
                      {" "}
                      {getDecryptedData(localStorage.getItem("fullname"))}
                    </h3>
                    {/* <p>varun@client.com</p> */}

                    <button
                      onClick={onDeleteAccount}
                      className="btn w-100 btn-red btn-danger"
                    >
                      <i className="fas fa-trash me-2"></i>
                      Delete this account
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Profile
