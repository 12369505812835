import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"

import { withTranslation } from "react-i18next"
import Loader from "pages/Loader"
import ActivePlan from "./ActivePlan"
import moment from "moment"
import Nopackage from "./Nopackage"
import axios from "axios"
import { ConfigUrl } from "ConfigUrl"

const ClientDashboard = props => {
  const [loading, setLoading] = useState(true)
  const [isPackage, setIsPackage] = useState(null)
  //meta title
  document.title = "Dashboard | Nutri Connect Pro - Customer Dashboard"

  const confirmPackage = async () => {
    setLoading(true)
    const today = moment().format("YYYY-MM-DD")
    const formData = new FormData()
    formData.append("client_id", localStorage.getItem("client_id"))
    formData.append("date", today)
    try {
      const response = await axios.post(
        `${ConfigUrl.apiUrl}check_date`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response.data.success) {
        setLoading(false)
        setIsPackage(true)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    confirmPackage()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className=" px-3">
          {/* Breadcrumbs */}
          {/* <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Your Diet Plan")}
          /> */}
          <Row>
            <Col className="col-12 p-0">
              <div className="page-title-box pb-0 d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18 mb-1">Your Diet Plan</h4>
                <p className="mb-sm-0" style={{ fontSize: "10px" }}>
                  Follow the diet plan to stay fit !
                </p>
              </div>
            </Col>
          </Row>
          {/* ............................................................ */}
          {!loading ? (
            isPackage ? (
              <section>
                <Row className="px-md-0 mb-5">
                  {/* <Col xl={12} className="mb-4 px-0">
              <div className="d-flex align-items-center">
                <img src={avatar} alt="" className="avatar-sm rounded" />
                <div className="ms-3 flex-grow-1">
                  <h5 className="mb-2 card-title">Hello, {getDecryptedData(localStorage.getItem("fullname"))}</h5>
                  <p className="text-muted mb-0">Welcome to your Diet Board</p>
                  <p className="text-muted mb-0">{"Token :" + localStorage.getItem("token")}</p>
                </div>
                <div>

                </div>
              </div>
            </Col> */}

                  <Col xl={12} className=" px-0">
                    <Row>
                      <div className="col-12">
                        <ActivePlan />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </section>
            ) : (
              <Nopackage />
            )
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ClientDashboard)
