import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import axios from "axios"
import Loader from "pages/Loader"
import moment from "moment"
import swal from "sweetalert"

import { getEncryptedData, getDecryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const ProgressIndex = () => {
  const [addProgessModal, setAddProgressModal] = useState(false)
  const [editProgessModal, setEditProgressModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  const [chest, setChest] = useState("")
  const [breast, setBreast] = useState("")
  const [waist, setWaist] = useState("")
  const [hips, setHips] = useState("")
  const [thighs, setThighs] = useState("")
  const [arms, setArms] = useState("")

  const [chest_unit, setChestUnit] = useState("Inch")
  const [breast_unit, setBreastUnit] = useState("Inch")
  const [waist_unit, setWaistUnit] = useState("Inch")
  const [hips_unit, setHipsUnit] = useState("Inch")
  const [thighs_unit, setThighsUnit] = useState("Inch")
  const [arms_unit, setArmsUnit] = useState("Inch")

  const [progress_id, setProgressId] = useState("")

  const [allProgress, setAllProgress] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append("client_id", localStorage.getItem("client_id"))

    axios
      .post(`${ConfigUrl.apiUrl}client_progress`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setAllProgress(getOriginalData(response.data.data))
        // console.log(response.data.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

    setPageSize(5)
  }, [])

  // get original data function
  const getOriginalData = (hashData = []) => {
    return hashData.map(hData => ({
      ...hData,
      chest: getDecryptedData(hData.chest),
      breast: getDecryptedData(hData.breast),
      waist: getDecryptedData(hData.waist),
      arms: getDecryptedData(hData.arms),
      hips: getDecryptedData(hData.hips),
      thighs: getDecryptedData(hData.thighs),
      chest_unit: getDecryptedData(hData.chest_unit),
      breast_unit: getDecryptedData(hData.breast_unit),
      waist_unit: getDecryptedData(hData.waist_unit),
      arms_unit: getDecryptedData(hData.arms_unit),
      hips_unit: getDecryptedData(hData.hips_unit),
      thighs_unit: getDecryptedData(hData.thighs_unit),
    }))
  }

  // Delete Plan Logic
  const onDeletePlan = progres_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to delete this Progress",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("progress_id", progres_id)
        formData.append("client_id", localStorage.getItem("client_id"))

        axios
          .post(`${ConfigUrl.apiUrl}delete_client_progress`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            setAllProgress(getOriginalData(response.data.data))

            swal("Progress has been deleted successfully!", {
              icon: "success",
            })
          })
          .catch(err => {
            swal("Something went wrong", "", "error")
            console.log(err)
          })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: "waist",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <span className="">
              {moment(cellProps.row.original.created_at).format(
                "DD-MM-YYYY | hh:mm:s"
              )}
            </span>
          )
        },
      },
      {
        Header:
          getDecryptedData(localStorage.getItem("gender")) == "male"
            ? "Chest"
            : "Breast",
        accessor:
          getDecryptedData(localStorage.getItem("gender")) == "male"
            ? "chest"
            : "Breast",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "col-md-4 text-center",
        Cell: cellProps => {
          return (
            <div>
              <button
                onClick={() => {
                  setEditProgressModal(true)
                  setChest(cellProps.row.original.chest)
                  setBreast(cellProps.row.original.breast)
                  setWaist(cellProps.row.original.waist)
                  setHips(cellProps.row.original.hips)
                  setThighs(cellProps.row.original.thighs)
                  setArms(cellProps.row.original.arms)

                  setChestUnit(cellProps.row.original.chest_unit)
                  setBreastUnit(cellProps.row.original.breast_unit)
                  setWaistUnit(cellProps.row.original.waist_unit)
                  setHipsUnit(cellProps.row.original.hips_unit)
                  setThighsUnit(cellProps.row.original.thighs_unit)
                  setArmsUnit(cellProps.row.original.arms_unit)
                  setProgressId(cellProps.row.original.id)
                }}
                className="btn bg-success bg-soft btn-sm ms-2"
              >
                <i className="fas fa-pen me-1"></i> Edit
              </button>

              <button
                onClick={() => {
                  setViewModal(true)
                  setChest(cellProps.row.original.chest)
                  setBreast(cellProps.row.original.breast)
                  setWaist(cellProps.row.original.waist)
                  setHips(cellProps.row.original.hips)
                  setThighs(cellProps.row.original.thighs)
                  setArms(cellProps.row.original.arms)

                  setChestUnit(cellProps.row.original.chest_unit)
                  setBreastUnit(cellProps.row.original.breast_unit)
                  setWaistUnit(cellProps.row.original.waist_unit)
                  setHipsUnit(cellProps.row.original.hips_unit)
                  setThighsUnit(cellProps.row.original.thighs_unit)
                  setArmsUnit(cellProps.row.original.arms_unit)
                }}
                className="btn bg-light-blue btn-sm ms-2"
              >
                <i className="fas fa-eye"></i>{" "}
                <span className="ps-1 d-md-inline d-none">View</span>
              </button>

              <button
                className="btn btn-red btn-sm ml-10"
                onClick={() => {
                  onDeletePlan(cellProps.row.original.id)
                }}
              >
                <i className="fas fa-trash"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allProgress, [allProgress])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // initialState: {
      //     sortBy: [
      //         {
      //             desc: true,
      //         },
      //     ],
      // },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onAddProgress = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append("chest", getEncryptedData(chest))
      formData.append("breast", getEncryptedData(breast))
      formData.append("waist", getEncryptedData(waist))
      formData.append("arms", getEncryptedData(arms))
      formData.append("hips", getEncryptedData(hips))
      formData.append("thighs", getEncryptedData(thighs))

      formData.append("chest_unit", getEncryptedData(chest_unit))
      formData.append("breast_unit", getEncryptedData(breast_unit))
      formData.append("waist_unit", getEncryptedData(waist_unit))
      formData.append("arms_unit", getEncryptedData(arms_unit))
      formData.append("hips_unit", getEncryptedData(hips_unit))
      formData.append("thighs_unit", getEncryptedData(thighs_unit))

      formData.append("client_id", localStorage.getItem("client_id"))

      const response = await axios.post(
        `${ConfigUrl.apiUrl}add_client_progress`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      setAllProgress(getOriginalData(response.data.data))

      setAddProgressModal(false)
      setLoading(false)

      swal("Progress Added successfully", {
        icon: "success",
      }).then(() => {})
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  const onEditProgress = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append("chest", getEncryptedData(chest))
      formData.append("breast", getEncryptedData(breast))
      formData.append("waist", getEncryptedData(waist))
      formData.append("arms", getEncryptedData(arms))
      formData.append("hips", getEncryptedData(hips))
      formData.append("thighs", getEncryptedData(thighs))

      formData.append("chest_unit", getEncryptedData(chest_unit))
      formData.append("breast_unit", getEncryptedData(breast_unit))
      formData.append("waist_unit", getEncryptedData(waist_unit))
      formData.append("arms_unit", getEncryptedData(arms_unit))
      formData.append("hips_unit", getEncryptedData(hips_unit))
      formData.append("thighs_unit", getEncryptedData(thighs_unit))

      formData.append("client_id", localStorage.getItem("client_id"))
      formData.append("progress_id", progress_id)

      const response = await axios.post(
        `${ConfigUrl.apiUrl}edit_client_progress`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      // console.log(response.data)
      setAllProgress(getOriginalData(response.data.data))
      setEditProgressModal(false)
      setLoading(false)

      swal("Progress Updated successfully", {
        icon: "success",
      }).then(() => {})
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <div className="ps-3">
            <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Progress"} />
          </div>

          <Row className="mx-0">
            <Card>
              <CardBody className="py-4 px-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold mb-2">Progress</h5>
                  <div className=" text-end ">
                    <button
                      onClick={() => setAddProgressModal(true)}
                      className="btn btn-green btn-cyan border-radius px-md-4 px-3"
                    >
                      <i className="fas fa-plus me-2"></i> Add Progress
                    </button>
                  </div>
                </div>

                <div className="table-responsive mt-3">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive-table responsive text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Progress Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-md-end justify-content-center align-items-center mt-3">
                  <div className="d-flex gap-1">
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={previousPage}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </Button>
                  </div>

                  <div className="px-3">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </div>

                  <div className="d-flex gap-1">
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={nextPage}
                      disabled={!canNextPage}
                    >
                      {">"}
                    </Button>
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {/* Add Progress Modal */}
      <Modal
        isOpen={addProgessModal}
        size="md"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title fw-bold text-purple mt-0">Add Progress</h5>
          <button
            type="button"
            onClick={() => {
              setAddProgressModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={onAddProgress}>
            <Row className="px-2 pb-3">
              {getDecryptedData(localStorage.getItem("gender")) == "male" ? (
                <div className="col-md-6 mb-4">
                  <Row>
                    <label htmlFor="">Chest </label>
                    <div className="col-8">
                      <input
                        type="number"
                        value={chest}
                        onChange={e => setChest(e.target.value)}
                        className="form-field"
                        placeholder="Enter Chest"
                      />
                    </div>
                    <div className="col-4 ps-0">
                      <select
                        name=""
                        id=""
                        value={chest_unit}
                        onChange={e => setChestUnit(e.target.value)}
                        className="form-control px-2 text-center form-field"
                      >
                        <option value="Inch">Inch</option>
                        <option value="Foot">Foot</option>
                        <option value="Cm">Cm</option>
                      </select>
                    </div>
                  </Row>
                </div>
              ) : (
                <div className="col-md-6 mb-4">
                  <Row>
                    <label htmlFor="">Breast </label>
                    <div className="col-8">
                      <input
                        type="number"
                        value={breast}
                        onChange={e => setBreast(e.target.value)}
                        className="form-field"
                        placeholder="Enter Breast"
                      />
                    </div>
                    <div className="col-4 ps-0">
                      <select
                        name=""
                        id=""
                        value={breast_unit}
                        onChange={e => setBreastUnit(e.target.value)}
                        className="form-control px-2 text-center form-field"
                      >
                        <option value="Inch">Inch</option>
                        <option value="Foot">Foot</option>
                        <option value="Cm">Cm</option>
                      </select>
                    </div>
                  </Row>
                </div>
              )}

              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Waist </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={waist}
                      onChange={e => setWaist(e.target.value)}
                      className="form-field"
                      placeholder="Enter Waist"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={waist_unit}
                      onChange={e => setWaistUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Hips </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={hips}
                      onChange={e => setHips(e.target.value)}
                      className="form-field"
                      placeholder="Enter Hips"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={hips_unit}
                      onChange={e => setHipsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>

              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Thighs </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={thighs}
                      onChange={e => setThighs(e.target.value)}
                      className="form-field"
                      placeholder="Enter Thighs"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={thighs_unit}
                      onChange={e => setThighsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Arms </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={arms}
                      onChange={e => setArms(e.target.value)}
                      className="form-field"
                      placeholder="Enter Arms"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={arms_unit}
                      onChange={e => setArmsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>

              <div className="col-12 text-center mt-4">
                <button className="btn btn-green btn-yellow border-radius px-4">
                  <i className="bx bx-send me-2"></i> Save Now
                </button>
              </div>
            </Row>
          </form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* Edit Progress Modal */}
      <Modal
        isOpen={editProgessModal}
        size="md"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title fw-bold text-purple mt-0">
            Edit Progress
          </h5>
          <button
            type="button"
            onClick={() => {
              setEditProgressModal(false)
              setChest("")
              setBreast("")
              setWaist("")
              setHips("")
              setThighs("")
              setArms("")
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={onEditProgress}>
            <Row className="px-2 pb-3">
              {getDecryptedData(localStorage.getItem("gender")) == "male" ? (
                <div className="col-md-6 mb-4">
                  <Row>
                    <label htmlFor="">Chest </label>
                    <div className="col-8">
                      <input
                        type="number"
                        value={chest}
                        onChange={e => setChest(e.target.value)}
                        className="form-field"
                        placeholder="Enter Chest"
                      />
                    </div>
                    <div className="col-4 ps-0">
                      <select
                        name=""
                        id=""
                        value={chest_unit}
                        onChange={e => setChestUnit(e.target.value)}
                        className="form-control px-2 text-center form-field"
                      >
                        <option value="Inch">Inch</option>
                        <option value="Foot">Foot</option>
                        <option value="Cm">Cm</option>
                      </select>
                    </div>
                  </Row>
                </div>
              ) : (
                <div className="col-md-6 mb-4">
                  <Row>
                    <label htmlFor="">Breast </label>
                    <div className="col-8">
                      <input
                        type="number"
                        value={breast}
                        onChange={e => setBreast(e.target.value)}
                        className="form-field"
                        placeholder="Enter Breast"
                      />
                    </div>
                    <div className="col-4 ps-0">
                      <select
                        name=""
                        id=""
                        value={breast_unit}
                        onChange={e => setBreastUnit(e.target.value)}
                        className="form-control px-2 text-center form-field"
                      >
                        <option value="Inch">Inch</option>
                        <option value="Foot">Foot</option>
                        <option value="Cm">Cm</option>
                      </select>
                    </div>
                  </Row>
                </div>
              )}

              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Waist </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={waist}
                      onChange={e => setWaist(e.target.value)}
                      className="form-field"
                      placeholder="Enter Waist"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={waist_unit}
                      onChange={e => setWaistUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Hips </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={hips}
                      onChange={e => setHips(e.target.value)}
                      className="form-field"
                      placeholder="Enter Hips"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={hips_unit}
                      onChange={e => setHipsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>

              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Thighs </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={thighs}
                      onChange={e => setThighs(e.target.value)}
                      className="form-field"
                      placeholder="Enter Thighs"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={thighs_unit}
                      onChange={e => setThighsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Arms </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={arms}
                      onChange={e => setArms(e.target.value)}
                      className="form-field"
                      placeholder="Enter Arms"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      name=""
                      id=""
                      value={arms_unit}
                      onChange={e => setArmsUnit(e.target.value)}
                      className="form-control px-2 text-center form-field"
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>

              <div className="col-12 text-center mt-4">
                <button className="btn btn-green btn-yellow border-radius px-4">
                  <i className="bx bx-send me-2"></i> Save Now
                </button>
              </div>
            </Row>
          </form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Progress Modal */}
      <Modal isOpen={viewModal} size="md" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title fw-bold text-purple mt-0">
            View Progress
          </h5>
          <button
            type="button"
            onClick={() => {
              setViewModal(false)
              setChest("")
              setBreast("")
              setWaist("")
              setHips("")
              setThighs("")
              setArms("")
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body ">
          <Row className="px-2 pb-3">
            {getDecryptedData(localStorage.getItem("gender")) == "male" ? (
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Chest </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={chest}
                      className="form-field"
                      disabled
                      placeholder="Enter Chest"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      value={chest_unit}
                      className="form-control px-2 text-center form-field"
                      disabled
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
            ) : (
              <div className="col-md-6 mb-4">
                <Row>
                  <label htmlFor="">Breast </label>
                  <div className="col-8">
                    <input
                      type="number"
                      value={breast}
                      className="form-field"
                      disabled
                      placeholder="Enter Breast"
                    />
                  </div>
                  <div className="col-4 ps-0">
                    <select
                      value={breast_unit}
                      className="form-control px-2 text-center form-field"
                      disabled
                    >
                      <option value="Inch">Inch</option>
                      <option value="Foot">Foot</option>
                      <option value="Cm">Cm</option>
                    </select>
                  </div>
                </Row>
              </div>
            )}

            <div className="col-md-6 mb-4">
              <Row>
                <label htmlFor="">Waist </label>
                <div className="col-8">
                  <input
                    type="number"
                    value={waist}
                    className="form-field"
                    disabled
                    placeholder="Enter Waist"
                  />
                </div>
                <div className="col-4 ps-0">
                  <select
                    value={waist_unit}
                    className="form-control px-2 text-center form-field"
                    disabled
                  >
                    <option value="Inch">Inch</option>
                    <option value="Foot">Foot</option>
                    <option value="Cm">Cm</option>
                  </select>
                </div>
              </Row>
            </div>
            <div className="col-md-6 mb-4">
              <Row>
                <label htmlFor="">Hips </label>
                <div className="col-8">
                  <input
                    type="number"
                    value={hips}
                    className="form-field"
                    disabled
                    placeholder="Enter Hips"
                  />
                </div>
                <div className="col-4 ps-0">
                  <select
                    value={hips_unit}
                    className="form-control px-2 text-center form-field"
                    disabled
                  >
                    <option value="Inch">Inch</option>
                    <option value="Foot">Foot</option>
                    <option value="Cm">Cm</option>
                  </select>
                </div>
              </Row>
            </div>

            <div className="col-md-6 mb-4">
              <Row>
                <label htmlFor="">Thighs </label>
                <div className="col-8">
                  <input
                    type="number"
                    value={thighs}
                    className="form-field"
                    disabled
                    placeholder="Enter Thighs"
                  />
                </div>
                <div className="col-4 ps-0">
                  <select
                    value={thighs_unit}
                    className="form-control px-2 text-center form-field"
                    disabled
                  >
                    <option value="Inch">Inch</option>
                    <option value="Foot">Foot</option>
                    <option value="Cm">Cm</option>
                  </select>
                </div>
              </Row>
            </div>
            <div className="col-md-6 mb-4">
              <Row>
                <label htmlFor="">Arms </label>

                <div className="col-8">
                  <input
                    type="number"
                    value={arms}
                    className="form-field"
                    disabled
                    placeholder="Enter Arms"
                  />
                </div>
                <div className="col-4 ps-0">
                  <select
                    value={arms_unit}
                    className="form-control px-2 text-center form-field"
                    disabled
                  >
                    <option value="Inch">Inch</option>
                    <option value="Foot">Foot</option>
                    <option value="Cm">Cm</option>
                  </select>
                </div>
              </Row>
            </div>

            <div className="col-12 text-center mt-4">
              <button
                onClick={() => setViewModal(false)}
                className="btn btn-green btn-yellow border-radius px-4"
              >
                <i className="fas fa-times me-1"></i> Close
              </button>
            </div>
          </Row>
        </div>
      </Modal>
      {/* -------------- */}
    </>
  )
}

export default ProgressIndex
