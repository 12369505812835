import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// client dashboard (Admin)
import ClientDashboard from "pages/ClientDashboard"
import AllDietPlans from "pages/ClientDashboard/AllDietPlans"
import AllInvoices from "pages/ClientDashboard/Invoices"
import ViewInvoice from "pages/ClientDashboard/ViewInvoice"
import ProgressIndex from "pages/ClientDashboard/ProgressIndex"
import RegisterUser from "pages/Authentication/RegisterUser"
import Profile from "pages/ClientDashboard/Profile"
import { ConfigUrl } from "ConfigUrl"

const authProtectedRoutes = [
  // admin
  { path: `${ConfigUrl.appUrl}dashboard`, component: <ClientDashboard /> },
  { path: `${ConfigUrl.appUrl}invoices`, component: <AllInvoices /> },
  {
    path: `${ConfigUrl.appUrl}view-invoice/:invoice_id`,
    component: <ViewInvoice />,
  },
  { path: `${ConfigUrl.appUrl}diet-plans`, component: <AllDietPlans /> },
  { path: `${ConfigUrl.appUrl}progress`, component: <ProgressIndex /> },
  { path: `${ConfigUrl.appUrl}user-profile`, component: <Profile /> },

  // //profile
  { path: `${ConfigUrl.appUrl}profile`, component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: `${ConfigUrl.appUrl}`,
    exact: true,
    component: <Navigate to={`${ConfigUrl.appUrl}dashboard`} />,
  },
]

const publicRoutes = [
  { path: `${ConfigUrl.appUrl}logout`, component: <Logout /> },
  { path: `${ConfigUrl.appUrl}login`, component: <Login /> },
  { path: `${ConfigUrl.appUrl}forgot-password`, component: <ForgetPwd /> },
  { path: `${ConfigUrl.appUrl}register`, component: <RegisterUser /> },

  // { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
