import React, { useState, useEffect } from "react"
import { Row, Table, Button } from "reactstrap"
//Import Breadcrumb
import "../../assets/css/ActivePlans.css"
import axios from "axios"
import Loader from "pages/Loader"
import moment from "moment"
import swal from "sweetalert"
import { ConfigUrl } from "ConfigUrl"

const ActivePlan = () => {
  const [allDietPlan, setAllDietPlan] = useState([])
  const [selectedDate, setSelectedDate] = useState([])
  const [currentDateIndex, setCurrentDateIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const today = new Date()
    const dayOfWeek = today.getDay()
    const mondayDate = new Date(today)
    const dates = []

    // Calculate Monday of the current week
    mondayDate.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1))

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(mondayDate)
      currentDate.setDate(mondayDate.getDate() + i)
      dates.push(moment(currentDate).format("YYYY-MM-DD"))
    }
    setSelectedDate(dates)

    async function getCurrPlan() {
      setLoading(true)
      const formData = new FormData()
      formData.append("client_id", localStorage.getItem("client_id"))

      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}dietplans`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          console.log(response)
          setLoading(false)
          setAllDietPlan(response.data.data)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    getCurrPlan()
  }, [])

  // Handle navigation to next day
  const goToNextDay = () => {
    if (currentDateIndex < selectedDate.length - 1 && currentDateIndex !== -1) {
      setCurrentDateIndex(currentDateIndex + 1)
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: smooth scrolling animation
      })
    }
  }
  const goToPreviousDay = () => {
    if (currentDateIndex > 0) {
      setCurrentDateIndex(currentDateIndex - 1)
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: smooth scrolling animation
      })
    }
  }

  const currentDate = selectedDate[currentDateIndex]

  useEffect(() => {
    setCurrentDateIndex(
      selectedDate.findIndex(date => date === moment().format("YYYY-MM-DD"))
    )
  }, [selectedDate])

  const handleDoneStatus = props => {
    console.log(props)
    swal({
      title: "Are you sure?",
      text: "Have you eaten this meal?",
      icon: "warning",
      buttons: true,
    }).then(async willOkay => {
      if (willOkay) {
        const formData = new FormData()
        formData.append("client_id", localStorage.getItem("client_id"))
        formData.append("diet_id", props.id)

        try {
          const response = await axios.post(
            `${ConfigUrl.apiUrl}change_status`,
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          if (response) {
            console.log("response", response)
            setAllDietPlan(response.data.data)
            swal({
              title: "Success!",
              text: "The status has been updated successfully.",
              icon: "success",
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  // Check client have current date plan or not

  return (
    <>
      {loading && <Loader />}
      <div className="">
        <section>
          <Row className="mb-3">
            <div className="col-12 ">
              <h5
                className="fw-bold py-2 d-flex align-items-center justify-content-between px-2 text-white mb-0 shadow"
                style={{ backgroundColor: "#862f86" }}
              >
                <button
                  // className=""
                  style={{ color: "#391b39" }}
                  onClick={goToPreviousDay}
                  disabled={currentDateIndex === 0}
                  className={`btn p-0 m-0 border-0 fs-5 ${
                    currentDateIndex === 0 ? "v-hidden" : ""
                  }`}
                >
                  {"<"}
                </button>{" "}
                <span>{moment(currentDate).format("dddd")}</span>
                <span style={{ color: "#2f0d2f" }}>
                  {moment(currentDate).format("DD-MM-YYYY")}
                </span>{" "}
                <button
                  style={{ color: "#391b39" }}
                  className={`btn p-0 m-0 border-0 fs-5 ${
                    currentDateIndex === selectedDate.length - 1 ||
                    currentDateIndex === -1
                      ? "v-hidden"
                      : ""
                  }`}
                  onClick={goToNextDay}
                  disabled={
                    currentDateIndex === selectedDate.length - 1 ||
                    currentDateIndex === -1
                  }
                >
                  {">"}
                </button>
              </h5>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button1 mt-2 mb-0">
                <i className="bx bxs-sun"></i>
                <span className="ps-2">On waking up</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() ===
                          "wakingup"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() === "wakingup"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button2 mt-2 mb-0">
                <i className="bx bxs-coffee"></i>
                <span className="ps-2">Breakfast</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() ===
                          "breakfast"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() === "breakfast"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button3 mt-2 mb-0">
                <i className="bx bx-food-menu"></i>
                <span className="ps-2">Mid Meal</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() === "midmeal"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() === "midmeal"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button4 mt-2 mb-0">
                <i className="bx bx-dish"></i>
                <span className="ps-2">Lunch</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() === "lunch"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() === "lunch"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button5 mt-2 mb-0">
                <i className="bx bx-restaurant"></i>
                <span className="ps-2">Evening Snack</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() ===
                          "eveningsnack"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() ===
                        "eveningsnack"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button6 mt-2 mb-0">
                <i className="bx bx-dish"></i>
                <span className="ps-2">Dinner</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() === "dinner"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() === "dinner"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <h5 className="fw-bold external-event p-1 ps-2 text-white fix-button7 mt-2 mb-0">
                <i className="bx bxs-moon"></i>
                <span className="ps-2">Post Dinner</span>
              </h5>
              <Table bordered className="shadow bg-white">
                <thead className="">
                  <tr>
                    <th className="col-2 text-center">Time</th>
                    <th className="col-8">Description</th>
                    <th className="col-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {allDietPlan
                    .filter(ob => {
                      return (
                        ob.date === currentDate &&
                        ob.type?.replace(/\s+/g, "").toLowerCase() ===
                          "postdinner"
                      )
                    })
                    ?.map((food, i) => {
                      return (
                        <tr
                          key={i}
                          className={`${
                            food.is_done === 1 ? "bg-light-2" : ""
                          }`}
                        >
                          {food.is_done === 1 ? (
                            <>
                              <td className="text-center">
                                <strike>{food.time_data}</strike>
                              </td>
                              <td>
                                <strike>{food.description}</strike>
                              </td>
                              <td>
                                <strike>Done</strike>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="text-center">{food.time_data}</td>
                              <td>{food.description}</td>

                              <td>
                                <button
                                  className="btn btn-outline-success btn-sm px-1 py-0 lh-sm"
                                  onClick={() => handleDoneStatus(food)}
                                >
                                  <i className="bi bi-check fs-2"></i>
                                </button>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  {allDietPlan.filter(ob => {
                    return (
                      ob.date === currentDate &&
                      ob.type?.replace(/\s+/g, "").toLowerCase() ===
                        "postdinner"
                    )
                  }).length === 0 && (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Row>
          {/* Pagination */}
          <div className="d-flex justify-content-between my-4">
            <Button
              onClick={goToPreviousDay}
              disabled={currentDateIndex === 0}
              className={`btn border-0 ${
                currentDateIndex === 0 ? "bg-light-purple" : "bg-purple"
              }`}
            >
              Previous Day
            </Button>
            <Button
              className={`btn border-0 ${
                currentDateIndex === selectedDate.length - 1 ||
                currentDateIndex === -1
                  ? "bg-light-purple"
                  : "bg-purple"
              }`}
              onClick={goToNextDay}
              disabled={
                currentDateIndex === selectedDate.length - 1 ||
                currentDateIndex === -1
              }
            >
              Next Day
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default ActivePlan
