import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link, useLocation } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import Css
import "../../assets/css/Layout/Header.css"

//i18n
import { withTranslation } from "react-i18next"
import { ConfigUrl } from "ConfigUrl"

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar" className="Header">
        <div className="navbar-header">
          <div className="d-flex ps-3">
            <div className="navbar-brand-box ps-1 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm pe-3 ps-0 font-size-16 header-item d-md-none d-inline"
                data-toggle="collapse"
                onClick={() => {
                  props.setMobileNavbar(!props.mobilenavbar)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>

              <Link to={`${ConfigUrl.appUrl}`} className="logo logo-light">
                <span>
                  {/* <img src={logoLight} alt="JobFitMeter logo" className="job-logo" height="48" /> */}
                  <h2
                    className="fw-bold text-white text-uppercase mb-0 "
                    style={{ textShadow: "1px 1px 2px gray" }}
                  >
                    Nutri Connect{" "}
                  </h2>
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <ProfileMenu />
            {/* <NotificationDropdown /> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
