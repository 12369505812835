import React from "react"
import "../../assets/css/Nopackage.css"

const Nopackage = () => {
  return (
    <div className="no-package">
      <p className="message">You don't have an active package !</p>
      <p className="action">Please contact your dietician !</p>
    </div>
  )
}

export default Nopackage
