import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

// import css
import "../../assets/css/Login/Login.css"
import axios from "axios"
import { PushNotifications } from "@capacitor/push-notifications"
import { ConfigUrl } from "ConfigUrl"

// import { Plugins } from '@capacitor/core';

// const { BackgroundTask } = Plugins;

const Login = props => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [show_pass, setShowPass] = useState(true)

  const [loading, setLoading] = useState(false)

  //meta title
  document.title = "Login | Nutri Connect Pro - Customer Dashboard"

  const navigate = useNavigate()

  const [token, setToken] = useState("")

  useEffect(() => {
    // Initialize PushNotifications
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
      } else {
        console.error("Push notifications permission denied")
      }
    })

    PushNotifications.addListener("registration", token => {
      setToken(token.value)
      localStorage.setItem("token", token.value)
      // Here you can send the token to your server or perform any other actions
    })
  }, [])

  const systemLogin = async e => {
    e.preventDefault()
    if (email && password) {
      try {
        setLoading(true)
        const formData = new FormData()

        formData.append("email", email)
        formData.append("password", password)

        const res = await axios.post(
          `${ConfigUrl.apiUrl}login_client`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        if (res.data.message == "success" && res.data.data.length > 0) {
          localStorage.setItem("client_id", res.data.data[0].id)
          localStorage.setItem("gender", res.data.data[0].gender)
          localStorage.setItem("fullname", res.data.data[0].fullname)
          localStorage.setItem("addedby", res.data.data[0].added_by)
          sendToken(res.data.data[0].id)
          // localStorage.setItem("is_active", res.data.data[0].is_active)
          navigate(`${ConfigUrl.appUrl}dashboard`)
        } else {
          setError(true)
          setErrorMsg(
            "Username and password are invalid. Please enter correct username and password"
          )
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setError(true)
        setErrorMsg(
          "Username and password are invalid. Please enter correct username and password"
        )
        setLoading(false)
      }
    } else {
      setError(true)
      setErrorMsg("Username and password is required!")
    }
  }

  const sendToken = async userId => {
    try {
      const formData = new FormData()

      formData.append("client_id", userId)
      formData.append("device_token", token)

      const res = await axios.post(
        `${ConfigUrl.apiUrl}device_token`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="login-page">
        <div className="account-pages login-bg">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card
                  className="overflow-hidden "
                  style={{
                    boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.39) ",
                  }}
                >
                  <div className="bg-light-purple">
                    <Row>
                      <div className="col-md-8 col-12">
                        <div className="text-dark p-4">
                          <h5 className="text-purple fw-bold fs-4">
                            Welcome Back !
                          </h5>
                          <p>
                            Sign in to continue to Nutri Connect Pro - Client
                            Dashboard.
                          </p>
                        </div>
                      </div>
                      <Col className="col-4 d-md-block d-none align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link
                        to={`${ConfigUrl.appUrl}`}
                        className="logo-light-element"
                      >
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form className="form-horizontal" onSubmit={systemLogin}>
                        {error ? (
                          <Alert color="danger">{errorMsg}</Alert>
                        ) : null}

                        <div className="mb-3 mt-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            value={email}
                            onChange={e => {
                              setError(false)
                              setEmail(e.target.value)
                            }}
                          />
                        </div>

                        <div className="mb-3 position-relative">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type={show_pass ? "password" : "text"}
                            placeholder="Enter Password"
                            value={password}
                            onChange={e => {
                              setError(false)
                              setPassword(e.target.value)
                            }}
                          />

                          <div
                            className="bd-eye-pass"
                            onClick={() => setShowPass(!show_pass)}
                          >
                            <i
                              className={
                                show_pass ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                            ></i>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-4 pt-2 d-grid">
                          <button
                            className="btn fw-bold text-purple btn-green bg-light-purple btn-block"
                            type="submit"
                          >
                            {loading && (
                              <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                            )}
                            {loading ? "Logging In.." : "Log In"}
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div> */}
                        <div className="mt-5 text-center">
                          <p>
                            Don&#39;t have an account ?{" "}
                            <Link
                              to={`${ConfigUrl.appUrl}register`}
                              className="fw-medium btn btn-yellow btn-sm ms-3"
                            >
                              {" "}
                              Signup now{" "}
                            </Link>{" "}
                          </p>
                          <p>
                            © {new Date().getFullYear()} Nutri Connect Pro{" "}
                            <i className="mdi mdi-heart text-danger" /> by
                            Zithas Technologies
                          </p>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
