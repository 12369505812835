import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, Navigate, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/default-user.png"
import { getDecryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const navigate = useNavigate()

  const logoutAdmin = () => {
    localStorage.removeItem("client_id")
    localStorage.removeItem("gender")
    localStorage.removeItem("fullname")
    navigate(`${ConfigUrl.appUrl}login`)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item pe-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user border"
            src={user1}
            alt="Header Avatar"
          />
          <span className=" me-1 text-white d-block">
            {getDecryptedData(localStorage.getItem("fullname"))}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to={`${ConfigUrl.appUrl}user-profile`}
            onClick={() => setMenu(false)}
            className="dropdown-item"
          >
            <i className="bx bx-user font-size-16 align-middle me-2 text-danger" />
            <span>{props.t("Profile")}</span>
          </Link>
          {/* <div className="dropdown-divider" /> */}
          <button onClick={logoutAdmin} className="dropdown-item  border-top">
            <i className="bx bx-power-off  font-size-16 align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
