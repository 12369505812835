import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"
import "../../assets/css/Layout/Header.css"
//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { ConfigUrl } from "ConfigUrl"

const Navbar = props => {
  return (
    <React.Fragment>
      <div className="topnav px-4" style={{ zIndex: "1001" }}>
        <div className="container-fluid px-0">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu "
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav d-flex justify-content-between w-100 d-md-block d-none">
                <div className="d-flex">
                  {/* dashboard */}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${ConfigUrl.appUrl}dashboard`}
                    >
                      <i className="bx bx-home-circle me-2 "></i>
                      {props.t("Diet Board")} {props.menuOpen}
                    </Link>
                  </li>

                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to="/diet-plans"
                    >
                      <i className="fas fa-list fs-6 me-2"></i>
                      {props.t("Diet Plans")} {props.menuOpen}
                    </Link>
                  </li> */}

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${ConfigUrl.appUrl}progress`}
                    >
                      <i className="fas fa-chart-line fs-6 me-2"></i>
                      {props.t("Progress")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-dark"
                      to={`${ConfigUrl.appUrl}invoices`}
                    >
                      <i className="fas fa-book fs-6 me-2"></i>
                      {props.t("Invoices")} {props.menuOpen}
                    </Link>
                  </li>
                </div>
              </ul>
            </Collapse>

            <div
              className={`mobile-menu d-md-none ${
                props.mobilenavbar ? "d-block" : "d-none"
              }`}
              onClick={() => props.setMobileNavbar(!props.mobilenavbar)}
            >
              <ul className="ps-1">
                <li>
                  <Link to={`${ConfigUrl.appUrl}dashboard`}>
                    {" "}
                    <i className="bx bx-home-circle me-2 "></i> Diet Board
                  </Link>
                </li>

                {/* <li>
                  <Link to={"/diet-plans"}>
                    {" "}
                    <i className="fas fa-list me-2 "></i> Diet Plans
                  </Link>
                </li> */}

                <li>
                  <Link to={`${ConfigUrl.appUrl}progress`}>
                    {" "}
                    <i className="fas fa-chart-line me-2 "></i> Progress
                  </Link>
                </li>

                <li>
                  <Link to={`${ConfigUrl.appUrl}invoices`}>
                    {" "}
                    <i className="fas fa-book me-2 "></i> Invoices
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
