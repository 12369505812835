import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import {
  CardBody,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import axios from "axios"
import Loader from "pages/Loader"
import moment from "moment"
import classnames from "classnames"
import { getDecryptedData } from "pages/Config"
import InvoicePdf from "./InvoicePdf"
import { ConfigUrl } from "ConfigUrl"

const AllInvoices = () => {
  const [allInvoices, setAllInvoices] = useState([])
  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false)
  const [paymentHistory, setPaymentHistory] = useState([])

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append("client_id", localStorage.getItem("client_id"))
    formData.append("added_by", localStorage.getItem("added_by"))

    axios
      .post(`${ConfigUrl.apiUrl}client_invoice`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // setAllInvoices(response.data.data)

        const originalData = response.data.data.map(invoice => ({
          ...invoice,
          customer_name: getDecryptedData(invoice.customer_name),
          terms: getDecryptedData(invoice.terms),
          customer_note: getDecryptedData(invoice.customer_note),
          discount: getDecryptedData(invoice.discount),
          subtotal: getDecryptedData(invoice.subtotal),
          service_details: getDecryptedData(invoice.service_details),
          invoice_date: getDecryptedData(invoice.invoice_date),
          duedate: getDecryptedData(invoice.duedate),
          startdate: getDecryptedData(invoice.startdate),
          enddate: getDecryptedData(invoice.enddate),
          total: Number(getDecryptedData(invoice.total)),
          unpaid_amount: Number(getDecryptedData(invoice.unpaid_amount)),
        }))

        setAllInvoices(originalData)
        console.log(originalData)
        setLoading(false)

        // console.log(response.data.data)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }, [])

  const viewPaymentHistory = async (added_by, client_id, inv_id) => {
    try {
      const formData = new FormData()

      formData.append("invoice_id", inv_id)
      formData.append("added_by", added_by)
      formData.append("client_id", client_id)

      const response = await axios.post(
        `${ConfigUrl.apiUrl}payments`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const OriginalData = response.data.data.map(history => ({
        ...history,
        payment_method: getDecryptedData(history.payment_method),
        amount: getDecryptedData(history.amount),
        date: getDecryptedData(history.date),
      }))

      setPaymentHistory(OriginalData)

      return true
    } catch (error) {
      swal("Something Went Wront", "", "error")
      console.log(error)

      return false
    }
  }

  return (
    <>
      <div className="page-content">
        {loading && <Loader />}
        <Container fluid>
          <div className="px-3">
            <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Invoices"} />
          </div>
          <Row className="mx-0">
            {allInvoices.map(invoice => {
              return (
                <div key={invoice.id} className="col-lg-6 px-md-3 px-1">
                  <Card className="px-0 mb-4">
                    <CardBody className="">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="text-secondary">
                            <i className="fas fa-arrow-circle-right  me-2"></i>
                            {invoice.invoice_number}
                          </h6>
                          <h5 className="mb-0 mt-3">
                            Total.{" "}
                            <span className="text-secondary">
                              ₹ {invoice.total}
                            </span>
                          </h5>
                        </div>
                        <div className="text-end">
                          <h6 className="text-secondary">
                            {moment(invoice.invoice_date).format("DD/MM/YYYY")}
                          </h6>

                          <h5 className="mb-0 mt-3">
                            Bal.{" "}
                            <span className="text-secondary">
                              ₹ {invoice.unpaid_amount}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="mt-3 pt-2 text-end border-top ">
                        <button
                          onClick={() => {
                            if (
                              viewPaymentHistory(
                                invoice.added_by,
                                invoice.client_id,
                                invoice.id
                              )
                            ) {
                              setPaymentHistoryModal(true)
                            }
                          }}
                          className="btn bg-light-blue btn-purple btn-sm mt-1 me-2"
                        >
                          <i className="fas fa-history me-1"></i>
                          history
                        </button>
                        <InvoicePdf invoice_id={invoice.id} />
                        <Link
                          to={`${ConfigUrl.appUrl}view-invoice/${invoice.id}`}
                          className="btn btn-yellow btn-sm mt-1"
                        >
                          <i className="fas fa-angle-double-right bx-tada me-1"></i>
                          View
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )
            })}

            {allInvoices.length == 0 && (
              <Card>
                <CardBody className="px-2">
                  <h5 className="mb-0 text-secondary">No Invoice Available</h5>
                </CardBody>
              </Card>
            )}
          </Row>

          {/* Payment Modal */}
          <Modal
            isOpen={paymentHistoryModal}
            size="md"
            className="bd-custom-md-3"
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Payment History</h5>
              <button
                type="button"
                onClick={() => {
                  setPaymentHistoryModal(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered text-center">
                <thead className="table-green">
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Mode</th>
                  </tr>
                </thead>

                <tbody>
                  {paymentHistory.map((payment, i) => {
                    return (
                      <tr key={payment.id}>
                        <td>{i + 1}</td>
                        <td>{moment(payment.date).format("DD-MM-YYYY")}</td>
                        <td>{payment.amount}</td>
                        <td>{payment.payment_method}</td>
                      </tr>
                    )
                  })}
                  {paymentHistory.length === 0 && (
                    <tr className="text-center">
                      <td colSpan={4}> No History Found </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal>
          {/* -------------- */}
        </Container>
      </div>
    </>
  )
}

export default AllInvoices
