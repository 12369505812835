import React, { useState } from "react"
import { Row, Col, CardBody, Card, Container, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import swal from "sweetalert"
import { getEncryptedData } from "pages/Config"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { ConfigUrl } from "ConfigUrl"

const RegisterUser = () => {
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [height, setHeight] = useState("")
  const [weight, setWeight] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [age, setAge] = useState("")
  const [gender, setGender] = useState("")

  const [loading, setLoading] = useState(false)

  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 150) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  document.title = "Register User | Nutri Connect Pro "

  const handleAddDietician = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()

      formData.append("firstname", getEncryptedData(fname))
      formData.append("lastname", getEncryptedData(lname))
      formData.append("height", getEncryptedData(height))
      formData.append("weight", getEncryptedData(weight))
      formData.append("email", email)
      formData.append("phone", getEncryptedData(phone))
      formData.append("age", getEncryptedData(age))
      formData.append("gender", getEncryptedData(gender))
      formData.append("password", password)

      const response = await axios.post(
        `${ConfigUrl.apiUrl}add_dietician`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setLoading(false)

      swal(
        "",
        "Your Account has been created successfully, now wait for account approval by admin.",
        "success"
      ).then(() => {
        navigate(`${ConfigUrl.appUrl}login`)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  return (
    <>
      <React.Fragment>
        <div className="login-page">
          <div className="account-pages login-bg">
            <Container>
              <Row className="justify-content-center py-5">
                <Col md={8} lg={6} xl={5}>
                  <Card
                    className="overflow-hidden "
                    style={{
                      boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.39) ",
                    }}
                  >
                    <div className="bg-light-purple">
                      <Row>
                        <div className="col-md-8 col-12">
                          <div className="text-dark p-4">
                            <h5 className="text-purple fw-bold fs-4 d-flex justify-content-between align-items-center">
                              <span
                                className="fs-6"
                                onClick={() => navigate(-1)}
                              >
                                {" "}
                                <i className="fas fa-arrow-left me-1"></i> Go
                                Back
                              </span>
                            </h5>

                            <h5 className="text-purple fw-bold mt-3">
                              Register Now{" "}
                            </h5>

                            <p>
                              Sign up to continue to Nutri Connect Pro - Client
                              Dashboard.
                            </p>
                          </div>
                        </div>
                        <Col className="col-4 d-md-block d-none align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link
                          to={`${ConfigUrl.appUrl}`}
                          className="logo-light-element"
                        >
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={handleAddDietician}
                        >
                          <Row>
                            <div className="col-12 mt-4">
                              <label htmlFor="">
                                Enter Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Row>
                                <div className="col-6">
                                  <input
                                    type="text"
                                    value={fname}
                                    onChange={e => setFname(e.target.value)}
                                    placeholder="First Name"
                                    className="form-control"
                                    required
                                  />
                                </div>
                                <div className="col-6">
                                  <input
                                    type="text"
                                    value={lname}
                                    onChange={e => setLname(e.target.value)}
                                    placeholder="Last Name"
                                    className="form-control"
                                  />
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-6 mt-3">
                              <label htmlFor="">Height</label>
                              <input
                                type="text"
                                value={height}
                                onChange={e => setHeight(e.target.value)}
                                placeholder="Enter Your Height"
                                className="form-control"
                              />
                            </div>

                            <div className="col-md-6 mt-3">
                              <label htmlFor="">Weight</label>
                              <input
                                type="text"
                                value={weight}
                                onChange={e => setWeight(e.target.value)}
                                placeholder="Enter Your Weight"
                                className="form-control"
                              />
                            </div>

                            <div className="col-12 mt-3">
                              <Row>
                                <div className="col-md-6">
                                  <label htmlFor="">
                                    Age <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={age}
                                    onChange={e => setAge(e.target.value)}
                                    placeholder="Enter Age"
                                    className="form-control"
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label htmlFor="">
                                    Gender{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="d-flex ">
                                    <div className="">
                                      <input
                                        type="radio"
                                        onChange={e => setGender(e.target.id)}
                                        name="gender"
                                        id="male"
                                        className="fs-5 form-check-input "
                                      />
                                      <label
                                        htmlFor="male"
                                        className="ps-2 pt-1"
                                      >
                                        Male
                                      </label>
                                    </div>

                                    <div className="ms-3">
                                      <input
                                        type="radio"
                                        onChange={e => setGender(e.target.id)}
                                        name="gender"
                                        id="female"
                                        className="form-check-input fs-5 "
                                      />
                                      <label
                                        htmlFor="female"
                                        className="ps-2 pt-1"
                                      >
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="col-md-6 mt-3">
                              <label htmlFor="">
                                Email Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder="Enter Email Address"
                                className="form-control"
                                required
                              />
                            </div>

                            <div className="col-md-6 mt-3">
                              <label htmlFor="">
                                Password <span className="text-danger">*</span>
                              </label>
                              <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Enter Password"
                                className="form-control"
                                required
                              />
                            </div>

                            <div className="col-md-6 mt-3">
                              <label htmlFor="">
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                placeholder="Enter Phone Number"
                                className="form-control"
                                required
                              />
                            </div>
                          </Row>

                          <div className="mt-4 pt-2 d-grid">
                            <button
                              className="btn fw-bold text-purple btn-green bg-light-purple btn-block"
                              type="submit"
                            >
                              {loading && (
                                <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                              )}
                              {loading ? "Creating account.." : "Register Now"}
                            </button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default RegisterUser
