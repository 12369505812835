import React from "react"
import {
  useAsyncDebounce,
} from "react-table";
import { Col } from "reactstrap";

const SearchFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  md
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  return (
    <Col md={md} className="d-flex justify-content-end">
      <div className="search-box me-xxl-2 my-xxl-0 d-inline-block w-100">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              id="search-bar-0"
              type="text"
              className="form-control border-radius"
              placeholder="Search ..."
              style={{ borderRadius: "0.25rem" }}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  )
}

export default SearchFilter
