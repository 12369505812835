import React, { useState } from "react"
import { Modal } from "reactstrap"

import {
  Document,
  Page,
  Text,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  View,
  Font,
} from "@react-pdf/renderer"
import axios from "axios"
import Loader from "pages/Loader"
import { getDecryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const MyInvoiceDocument = ({ InvoiceData, serviceDetails, inv_structure }) => {
  Font.register({
    family: "Noto Sans",
    fonts: [
      {
        src: "https://fonts.boords.com/NotoSans-Regular.ttf",
        fontWeight: "bold",
      },
    ],
  })

  const address = inv_structure?.address && JSON.parse(inv_structure?.address)

  const finalTerms = inv_structure?.terms
    ?.split("</p><p>")
    .map(item => item.replaceAll("<p>", "").replaceAll("</p>", ""))

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 45,
      paddingHorizontal: 30,
      position: "relative",
      fontFamily: "Noto Sans",
    },
    table: {
      display: "table",
      width: "47%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#999",
      borderRightWidth: 0,
      borderBottomWidth: 0,
      textAlign: "left !important",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#999",
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCell: {
      margin: 5,
      fontSize: 9,
    },
    paragraph: {
      fontSize: "10px",
      marginTop: "13px",
      lineHeight: "1.5px",
    },
  })

  return (
    <Document style={styles.body}>
      {/* table */}
      <Page size="A4" style={styles.body}>
        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          {/* Header section */}
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <Text
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                {inv_structure?.name}
              </Text>
            </View>

            <View style={{ alignItems: "flex-end" }}>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {address?.line1}
              </Text>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {address?.line2}
              </Text>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {address?.line3}
              </Text>
            </View>
          </View>

          {/* Lines */}
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View style={{ width: "33%" }}>
              <View
                style={{
                  borderBottom: "1px solid gray",
                  transform: "translateY(10)",
                }}
              ></View>
            </View>
            <View style={{ width: "34%" }}>
              <Text style={{ textAlign: "center", fontSize: 14 }}>INVOICE</Text>
            </View>
            <View style={{ width: "33%" }}>
              <View
                style={{
                  borderBottom: "1px solid gray",
                  transform: "translateY(10)",
                }}
              ></View>
            </View>
          </View>

          {/* address table */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <View style={{ width: "40%" }}>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {InvoiceData?.customer_name},
              </Text>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {getDecryptedData(InvoiceData?.client?.address)},
              </Text>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {getDecryptedData(InvoiceData?.client?.city) +
                  ", " +
                  getDecryptedData(InvoiceData?.client?.state)}
                ,
              </Text>
              <Text style={{ fontSize: 10, marginBottom: 2 }}>
                {getDecryptedData(InvoiceData?.client?.country)}.
              </Text>
            </View>

            <View style={{ width: "42%" }}>
              <View style={[styles.table, { width: "100%" }]}>
                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: "40%" }}>
                    <Text style={styles.tableCell}>{"Invoice#"}</Text>
                  </View>
                  <View
                    style={{ ...styles.tableCol, width: "60%", color: "#666" }}
                  >
                    <Text style={styles.tableCell}>
                      {InvoiceData?.invoice_number}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: "40%" }}>
                    <Text style={styles.tableCell}>{"Invoice Date"}</Text>
                  </View>
                  <View
                    style={{ ...styles.tableCol, width: "60%", color: "#666" }}
                  >
                    <Text style={styles.tableCell}>
                      {InvoiceData?.invoice_date}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: "40%" }}>
                    <Text style={styles.tableCell}>{"Terms"}</Text>
                  </View>
                  <View
                    style={{ ...styles.tableCol, width: "60%", color: "#666" }}
                  >
                    <Text style={styles.tableCell}>{InvoiceData?.terms}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={{ ...styles.tableCol, width: "40%" }}>
                    <Text style={styles.tableCell}>{"Due Date"}</Text>
                  </View>
                  <View
                    style={{ ...styles.tableCol, width: "60%", color: "#666" }}
                  >
                    <Text style={styles.tableCell}>{InvoiceData?.duedate}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* table */}
          <View style={{ ...styles.table, width: "100%", marginTop: "30px" }}>
            <View
              style={{
                ...styles.tableRow,
                backgroundColor: "#fad1fa",
                width: "100%",
                color: "#6e276c",
                fontFamily: "Noto Sans",
                fontWeight: 900,
              }}
            >
              <View
                style={{ ...styles.tableCol, width: "5%", textAlign: "center" }}
              >
                <Text style={styles.tableCell}>ID</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "50%" }}>
                <Text style={styles.tableCell}>Package & Description</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "11.25%",
                  textAlign: "center",
                }}
              >
                <Text style={styles.tableCell}>Qty</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "11.25%",
                  textAlign: "center",
                }}
              >
                <Text style={styles.tableCell}>Rate</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "11.25%",
                  textAlign: "center",
                }}
              >
                <Text style={styles.tableCell}>Tax</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "11.25%",
                  textAlign: "center",
                }}
              >
                <Text style={styles.tableCell}>Amount</Text>
              </View>
            </View>
            {serviceDetails?.map((test, i) => {
              return (
                <View key={i} style={{ ...styles.tableRow }}>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Text style={styles.tableCell}>{i + 1}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: "50%" }}>
                    <Text style={styles.tableCell}>{test.service}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "11.25%",
                      textAlign: "center",
                    }}
                  >
                    <Text style={styles.tableCell}>{test.quantity}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "11.25%",
                      textAlign: "center",
                    }}
                  >
                    <Text style={styles.tableCell}>{test.rate}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "11.25%",
                      textAlign: "center",
                    }}
                  >
                    <Text style={styles.tableCell}>{test.tax}</Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "11.25%",
                      textAlign: "center",
                    }}
                  >
                    <Text style={styles.tableCell}>{test.amount}</Text>
                  </View>
                </View>
              )
            })}
          </View>

          <Text style={styles.paragraph}>
            Thank you for giving us an opportunity! Hoping work from you in
            future
          </Text>

          {/* Totals */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 30,
            }}
          >
            <View style={{ width: "40%" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 10 }}>Sub Total</Text>
                <Text style={{ fontSize: 10 }}>₹ {InvoiceData?.subtotal}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px 0",
                }}
              >
                <Text style={{ fontSize: 10 }}>Discount (%)</Text>
                <Text style={{ fontSize: 10 }}>
                  {InvoiceData?.discount &&
                    InvoiceData?.discount != "undefined" &&
                    InvoiceData?.discount}
                  %
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderTop: "1px solid gray",
                  paddingTop: 4,
                }}
              >
                <Text style={{ fontSize: 10 }}> Total (₹)</Text>
                <Text style={{ fontSize: 10 }}>
                  ₹ {Number(InvoiceData?.total)?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>

          {/* bank details */}
          <Text style={{ marginTop: 30, fontSize: 16, marginBottom: 4 }}>
            Bank Details
          </Text>
          <View style={{ width: "100%" }}>
            <View
              style={[
                styles.table,
                { width: "50%", borderTop: "none", borderLeft: "none" },
              ]}
            >
              <View style={styles.tableRow}>
                <View
                  style={{ ...styles.tableCol, width: "30%", border: "none" }}
                >
                  <Text style={styles.tableCell}>Bank Name : </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    width: "70%",
                    color: "#666",
                    border: "none",
                  }}
                >
                  <Text style={styles.tableCell}>
                    {inv_structure?.bank_name}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View
                  style={{ ...styles.tableCol, width: "30%", border: "none" }}
                >
                  <Text style={styles.tableCell}>Branch Name : </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    width: "70%",
                    color: "#666",
                    border: "none",
                  }}
                >
                  <Text style={styles.tableCell}>
                    {inv_structure?.branch_name}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View
                  style={{ ...styles.tableCol, width: "30%", border: "none" }}
                >
                  <Text style={styles.tableCell}>Account Name : </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    width: "70%",
                    color: "#666",
                    border: "none",
                  }}
                >
                  <Text style={styles.tableCell}>
                    {inv_structure?.account_name}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View
                  style={{ ...styles.tableCol, width: "30%", border: "none" }}
                >
                  <Text style={styles.tableCell}>Account No. : </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    width: "70%",
                    color: "#666",
                    border: "none",
                  }}
                >
                  <Text style={styles.tableCell}>
                    {inv_structure?.account_no}{" "}
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View
                  style={{ ...styles.tableCol, width: "30%", border: "none" }}
                >
                  <Text style={styles.tableCell}>IFSC Code : </Text>
                </View>
                <View
                  style={{
                    ...styles.tableCol,
                    width: "70%",
                    color: "#666",
                    border: "none",
                  }}
                >
                  <Text style={styles.tableCell}>
                    {inv_structure?.ifsc_code}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Terms and conditions */}
          <Text style={{ marginTop: 20, fontSize: 16, marginBottom: 4 }}>
            Terms & Conditions
          </Text>
          <View style={{ marginTop: 2 }}>
            {finalTerms?.map((term, i) => {
              return (
                <Text key={i} style={{ fontSize: 10, marginBottom: 2 }}>
                  {term}
                </Text>
              )
            })}
          </View>
        </View>
        {/* =============== */}
      </Page>
    </Document>
  )
}

const InvoicePdf = ({ invoice_id }) => {
  const [viewData, setViewData] = useState(false)

  const [InvoiceData, setInvoiceData] = useState({})
  const [serviceDetails, setServiceDetails] = useState([])

  const [inv_structure, setInvStructure] = useState({})
  const [loading, setLoading] = useState(false)

  const FetchInvoiceData = async () => {
    const formData = new FormData()
    formData.append("invoice_id", invoice_id)
    formData.append("added_by", localStorage.getItem("addedby"))

    axios
      .post(`${ConfigUrl.apiUrl}view_client_invoice`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        const oriData = {
          ...response.data.data.invoice_details,
          account_name: getDecryptedData(
            response.data.data.invoice_details.account_name
          ),
          account_no: getDecryptedData(
            response.data.data.invoice_details.account_no
          ),
          address: getDecryptedData(response.data.data.invoice_details.address),
          bank_name: getDecryptedData(
            response.data.data.invoice_details.bank_name
          ),
          branch_name: getDecryptedData(
            response.data.data.invoice_details.branch_name
          ),
          ifsc_code: getDecryptedData(
            response.data.data.invoice_details.ifsc_code
          ),
          name: getDecryptedData(response.data.data.invoice_details.name),
          terms: getDecryptedData(response.data.data.invoice_details.terms),
        }

        setInvStructure(oriData)

        const oriData2 = {
          ...response.data.data,
          customer_name: getDecryptedData(response.data.data.customer_name),
          customer_note: getDecryptedData(response.data.data.customer_note),
          discount: getDecryptedData(response.data.data.discount),
          subtotal: getDecryptedData(response.data.data.subtotal),
          terms: getDecryptedData(response.data.data.terms),
          service_details: getDecryptedData(response.data.data.service_details),
          invoice_date: getDecryptedData(response.data.data.invoice_date),
          duedate: getDecryptedData(response.data.data.duedate),
          startdate: getDecryptedData(response.data.data.startdate),
          enddate: getDecryptedData(response.data.data.enddate),
          total: Number(getDecryptedData(response.data.data.total)),
          unpaid_amount: Number(
            getDecryptedData(response.data.data.unpaid_amount)
          ),
        }
        setInvoiceData(oriData2)

        setServiceDetails(JSON.parse(oriData2.service_details))
        setViewData(true)
        setLoading(false)
      })
      .catch(err => {
        swal("Something went wrong", "", "error")
        console.log(err)
      })
  }

  return (
    <>
      {loading && <Loader />}

      <button
        onClick={() => {
          // fetching proposal data
          FetchInvoiceData()
        }}
        className="btn btn-purple btn-sm mt-1 me-2"
      >
        <i className="fas fa-download"></i>
      </button>

      <Modal size="xl" centered isOpen={viewData}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Download Invoice</h5>
          <div className="d-flex align-items-center">
            <div>
              {/* <PDFDownloadLink document={<MyInvoiceDocument />} fileName="Proposal.pdf">
                {({ blob, url, loading, error }) => <a title="download pdf" className="">
                  <i className="fa fa-download text-purple pr-10"></i>
                </a>}
              </PDFDownloadLink> */}
            </div>
            <button
              type="button"
              onClick={() => {
                setViewData(false)
              }}
              className="btn-modal-close"
              style={{ marginTop: "8px" }}
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
        </div>
        <div className="modal-body overflow-auto">
          <PDFViewer width="100%" height="800px" showToolbar>
            <MyInvoiceDocument
              InvoiceData={InvoiceData}
              serviceDetails={serviceDetails}
              inv_structure={inv_structure}
            />
          </PDFViewer>
        </div>
      </Modal>
    </>
  )
}

export default InvoicePdf
