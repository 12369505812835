import React, { useState, useEffect, Fragment, useMemo } from "react"
import { Col, Container, Row, Table, Button, Modal } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Form } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import {
  CardBody,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import axios from "axios"
import Loader from "pages/Loader"
import moment from "moment"
import classnames from "classnames"
import { getDecryptedData, getEncryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const AllDietPlans = () => {
  const [viewPlanModal, setViewPlanModal] = useState(false)
  const [verticalActiveTab2, setverticalActiveTab2] = useState(1)

  const [allActivePlans, setAllActivePlans] = useState([])
  const [sideTab, setSideTab] = useState(false)

  const [loading, setLoading] = useState(false)

  const [wakingUpFoods, setWakingUpFoods] = useState([{ time: "", desc: "" }])
  const [breakfastFoods, setBreakfastFoods] = useState([{ time: "", desc: "" }])
  const [midMealFoods, setMidMealFoods] = useState([{ time: "", desc: "" }])
  const [lunchFoods, setLunchFoods] = useState([{ time: "", desc: "" }])
  const [eveningSnackFoods, setEveningSnackFoods] = useState([
    { time: "", desc: "" },
  ])
  const [dinnerFoods, setDinnerFoods] = useState([{ time: "", desc: "" }])
  const [postDinnerFoods, setPostDinnerFoods] = useState([
    { time: "", desc: "" },
  ])
  const [planNote, setPlanNote] = useState([{ time: "", desc: "" }])
  const [plan_id, setPlanId] = useState("")
  const [planDate, setPlanDate] = useState("")

  useEffect(() => {
    const getActivePlans = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("client_id", localStorage.getItem("client_id"))
        formData.append("added_by", localStorage.getItem("addedby"))

        const response = await axios.post(
          `${ConfigUrl.apiUrl}client_dietplan`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        const newPlans = response.data.filter(plan => plan.status == 1)
        console.log("Diet plans", newPlans, response.data)

        setAllActivePlans(newPlans)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    getActivePlans()
    // getDietPlan()
  }, [])

  useEffect(() => setSideTab(false), [verticalActiveTab2])

  // getting all diet plan
  // const getDietPlan = async () => {

  //     setLoading(true)

  //     try {
  //         const formData = new FormData()
  //         formData.append("client_id", localStorage.getItem("client_id"))
  //         formData.append("added_by", localStorage.getItem("adminId"))

  //         const response = await axios.post(`${ConfigUrl.apiUrl}client_dietplan`, formData, {
  //             headers: {
  //                 Accept: "application/json",
  //                 "Content-Type": "multipart/form-data",
  //             }
  //         })
  //         // console.log(response.data)
  //         // dividing whole array by 7
  //         const newArray = Array.from({ length: Math.ceil(response.data.length / 7) }, (_, index) =>
  //             response.data.slice(index * 7, (index + 1) * 7)
  //         );

  //         console.log("weeks", newArray)
  //         // setWeekList(newArray.length)

  //         setLoading(false)
  //     } catch (error) {
  //         console.log(error)
  //     }

  // }

  // view plan
  const fetchDietPlan = plan_date => {
    const formData = new FormData()
    formData.append("client_id", localStorage.getItem("client_id"))
    formData.append("date", plan_date)

    axios
      .post(`${ConfigUrl.apiUrl}view_dietplan`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setWakingUpFoods(
          JSON.parse(getDecryptedData(response.data.data[0].on_waking_up))
        )
        setBreakfastFoods(
          JSON.parse(getDecryptedData(response.data.data[0].breakfast))
        )
        setMidMealFoods(
          JSON.parse(getDecryptedData(response.data.data[0].mid_meal))
        )
        setLunchFoods(JSON.parse(getDecryptedData(response.data.data[0].lunch)))
        setEveningSnackFoods(
          JSON.parse(getDecryptedData(response.data.data[0].evening_snack))
        )
        setDinnerFoods(
          JSON.parse(getDecryptedData(response.data.data[0].dinner))
        )
        setPostDinnerFoods(
          JSON.parse(getDecryptedData(response.data.data[0].post_dinner))
        )
        setPlanNote(getDecryptedData(response.data.data[0].note))
        setPlanId(response.data.data[0].id)
        setViewPlanModal(true)

        return true
      })
      .catch(err => {
        console.log(err)
        return false
      })
  }

  // ondone ==================================================================
  // Edit Diet Plan =====================================================
  const handleEditDietPlan = async () => {
    try {
      const formData = new FormData()
      formData.append(
        "on_waking_up",
        getEncryptedData(JSON.stringify(wakingUpFoods))
      )
      formData.append(
        "breakfast",
        getEncryptedData(JSON.stringify(breakfastFoods))
      )
      formData.append(
        "mid_meal",
        getEncryptedData(JSON.stringify(midMealFoods))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunchFoods)))
      formData.append("dinner", getEncryptedData(JSON.stringify(dinnerFoods)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(eveningSnackFoods))
      )
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(postDinnerFoods))
      )
      formData.append("client_id", localStorage.getItem("client_id"))
      formData.append("date", planDate)
      formData.append("note", getEncryptedData(planNote))
      formData.append("plan_id", plan_id)

      const response = await axios.post(
        `${ConfigUrl.apiUrl}edit_dietplan`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setWakingUpFoods(
        JSON.parse(getDecryptedData(response.data.data[0].on_waking_up))
      )
      setBreakfastFoods(
        JSON.parse(getDecryptedData(response.data.data[0].breakfast))
      )
      setMidMealFoods(
        JSON.parse(getDecryptedData(response.data.data[0].mid_meal))
      )
      setLunchFoods(JSON.parse(getDecryptedData(response.data.data[0].lunch)))
      setEveningSnackFoods(
        JSON.parse(getDecryptedData(response.data.data[0].evening_snack))
      )
      setDinnerFoods(JSON.parse(getDecryptedData(response.data.data[0].dinner)))
      setPostDinnerFoods(
        JSON.parse(getDecryptedData(response.data.data[0].post_dinner))
      )
      setPlanNote(getDecryptedData(response.data.data[0].note))
      setPlanId(response.data.data[0].id)

      swal("", "Successfully Done!", "success")
    } catch (error) {
      console.log(error)
      swal("Something went wrong", "", "error")
    }
  }

  const onDonePlanItem = (cateName, itemId) => {
    // console.log(cateName, itemId)

    swal({
      title: "Are you sure?",
      text: "You are done with this item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        if (cateName === "wakingUpFoods") {
          let newFood = [...wakingUpFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })

          setWakingUpFoods(newFood)
        } else if (cateName === "breakfastFoods") {
          let newFood = [...breakfastFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setBreakfastFoods(newFood)
        } else if (cateName === "midMealFoods") {
          let newFood = [...midMealFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setMidMealFoods(newFood)
        } else if (cateName === "lunchFoods") {
          let newFood = [...lunchFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setLunchFoods(newFood)
        } else if (cateName === "eveningSnackFoods") {
          let newFood = [...eveningSnackFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setEveningSnackFoods(newFood)
        } else if (cateName === "dinnerFoods") {
          let newFood = [...dinnerFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setDinnerFoods(newFood)
        } else if (cateName === "postDinnerFoods") {
          let newFood = [...postDinnerFoods]
          newFood.map(food => {
            if (food.id == itemId) food.isDone = 1
            return food
          })
          setPostDinnerFoods(newFood)
        }
        handleEditDietPlan()
      } else {
        // fetchDietPlan(planDate)
      }
    })
  }

  const navigate = useNavigate()

  useEffect(() => {
    setPageSize(5)
  }, [])

  const toggleVertical2 = tab => {
    if (verticalActiveTab2 !== tab) {
      setverticalActiveTab2(tab)
    }
  }

  const planTime = useMemo(() => {
    switch (verticalActiveTab2) {
      case 1:
        return "Morning Diet Plan"
      case 2:
        return "Afternoon Diet Plan"
      case 3:
        return "Evening Diet Plan"
      case 4:
        return "Notes"
    }
  }, [verticalActiveTab2])

  const columns = useMemo(
    () => [
      // {
      //     Header: "ID",
      //     accessor: "id",
      //     disableFilters: true,
      //     filterable: true,
      //     className: "col-md-1 text-center"
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <span className="">
              {moment(cellProps.row.original.date).format("DD-MM-YYYY")}
            </span>
          )
        },
      },
      {
        Header: "Day",
        accessor: "day",
        disableFilters: true,
        filterable: true,
      },
      // {
      //     Header: "Status",
      //     accessor: "status",
      //     disableFilters: true,
      //     filterable: true,
      // },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "col-md-3 text-center",
        Cell: cellProps => {
          return (
            <div>
              <button
                onClick={() => {
                  setPlanDate(cellProps.row.original.date)
                  if (fetchDietPlan(cellProps.row.original.date)) {
                    setViewPlanModal(true)
                  }
                }}
                className="btn bg-light-blue btn-purple btn-sm"
              >
                <i className="fas fa-eye me-1"></i> View Plan
              </button>

              {/* <button onClick={() => setViewPlanModal(true)} className="btn bg-light-blue btn-sm">
                                <i className="fas fa-eye me-1"></i> View
                            </button>
                            <button
                                className="btn btn-success  btn-sm ms-2"
                            >
                                <i className="fas fa-check me-1"></i> Done
                            </button> */}
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allActivePlans, [allActivePlans])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  return (
    <>
      <div className="page-content">
        {loading && <Loader />}
        <Container fluid>
          <div className="px-3">
            <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Diet Plans"} />
          </div>
          <Row className="mx-0">
            <Card className="px-0">
              <CardBody className="py-4">
                <Row className=" justify-space-between flex-md-row flex-column-reverse">
                  <Col md={2}>
                    <select
                      className="form-select border-radius d-md-block d-none"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <div className="d-md-none mt-3">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>
                  </Col>
                  <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                    <div className="d-md-block d-none">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h5 className="d-md-none fw-bold d-block">
                        All Diet Plans
                      </h5>
                      <div>
                        <select
                          className="form-select d-md-none  border-radius"
                          value={pageSize}
                          onChange={onChangeInSelect}
                        >
                          {[5, 10, 20, 30].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </Row>

                <div className="table-responsive mt-3">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive-table text-center responsive"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td
                            data-label="DietPlans"
                            colSpan={7}
                            className="text-center"
                          >
                            No DietPlan Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-md-end justify-content-center align-items-center mt-3">
                  <div className="d-flex gap-1">
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={previousPage}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </Button>
                  </div>

                  <div className="px-3">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </div>

                  <div className="d-flex gap-1">
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={nextPage}
                      disabled={!canNextPage}
                    >
                      {">"}
                    </Button>
                    <Button
                      className="btn-green bg-light-purple"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {/* View Dietplan Modal */}
      <Modal
        isOpen={viewPlanModal}
        className="bd-custom-md-3 modal-fullscreen"
        centered
      >
        <div className="modal-header py-3 px-4 border-bottom-0 bg-light-purple">
          <h5 className="modal-title mt-0 text-purple">View Diet Plan</h5>
          <button
            type="button"
            onClick={() => {
              setViewPlanModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="px-md-4">
            <Row className="mt-4 px-md-4">
              {/* ========================================== */}

              <div className="col-md-3 ">
                <div
                  className="d-md-none d-flex text-purple align-items-center"
                  onClick={() => setSideTab(true)}
                >
                  <i className="fs-5 fas fa-bars me-2 p-2  bg-light-cyan rounded-circle"></i>
                  <span className="fw-bold fs-5">{planTime}</span>
                </div>
                <div className={`mobile-sidetab h-100 ${sideTab && "showTab"}`}>
                  <Nav
                    pills
                    className={`side-tab flex-column px-2 h-100 py-3 diet-plan-tab`}
                  >
                    <div className="text-end d-md-none d-block">
                      <i
                        onClick={() => setSideTab(false)}
                        className="fs-4 text-purple fas fa-times mb-4"
                      ></i>
                    </div>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab2 === 1,
                        })}
                        onClick={() => {
                          toggleVertical2(1)
                        }}
                      >
                        Morning Diet Plan
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab2 === 2,
                        })}
                        onClick={() => {
                          toggleVertical2(2)
                        }}
                      >
                        Afternoon Diet Plan
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab2 === 3,
                        })}
                        onClick={() => {
                          toggleVertical2(3)
                        }}
                      >
                        Evening Diet Plan
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab2 === 4,
                        })}
                        onClick={() => {
                          toggleVertical2(4)
                        }}
                      >
                        View Notes
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="col-md-9 ps-md-4">
                <TabContent
                  activeTab={verticalActiveTab2}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId={1}>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5 className="">On waking up</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {wakingUpFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem(
                                            "wakingUpFoods",
                                            food.id
                                          )
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline ">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Breakfast</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {breakfastFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem(
                                            "breakfastFoods",
                                            food.id
                                          )
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={2}>
                    <Row>
                      <div className="col-12  mb-3">
                        <h5 className="">Mid meal</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {midMealFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem(
                                            "midMealFoods",
                                            food.id
                                          )
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Lunch</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lunchFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem("lunchFoods", food.id)
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={3}>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5 className="">Evening Snack</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eveningSnackFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem(
                                            "eveningSnackFoods",
                                            food.id
                                          )
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12 mb-3">
                        <h5 className="">Dinner</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dinnerFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem("dinnerFoods", food.id)
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Post Dinner</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-8">Description</th>
                              <th className="col-2 text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {postDinnerFoods.map((food, i) => {
                              return food.isDone != 1 ? (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                  <td className="text-center">
                                    {food.desc.length > 0 && (
                                      <button
                                        onClick={() =>
                                          onDonePlanItem(
                                            "postDinnerFoods",
                                            food.id
                                          )
                                        }
                                        className="btn btn-sm btn-success"
                                      >
                                        <i className="fas fa-check"></i>{" "}
                                        <span className="d-md-inline d-none">
                                          Done
                                        </span>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                    className="text-center"
                                  >
                                    {food.time}
                                  </td>
                                  <td
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    {food.desc}
                                  </td>
                                  <td className="text-center">
                                    <button className="btn btn-sm bg-danger bg-soft text-danger">
                                      <span className="d-md-inline">--</span>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={4}>
                    <ul className="view-box bg-light bg-soft">
                      <li>{planNote}</li>
                    </ul>
                  </TabPane>
                </TabContent>
              </div>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AllDietPlans
